.ht-contact-cards {
    margin-top: 32px;

    & .ht-wrapper {
        display: flex;
        align-items: stretch;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
    }

    & h2 {
        display: block;
        width: 100%;
        text-align: center;
    }

    & .ht-contact-card {
        width: calc(50% - 16px);
        background-color: var(--white);
        border-radius: var(--default);
        box-shadow: var(--box-shadow);

        &.ht-3-col {
            width: 315px;
        }

        & .ht-contact--image {
            position: relative;

            & img {
                width: 100%;
                height: 257px;
                object-fit: cover;
                object-position: top;
                margin: 0 auto;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                width: 0;
                height: 0;
                border-bottom: 125px solid var(--orange);
                border-left: 70px solid transparent;
                transition: none;
                z-index: 1;
            }
        }

        & .ht-contact--details {
            padding: 32px;

            & h3 {
                margin-bottom: 16px;
                text-align: center;
            }

            & p {
                text-align: center;

                & a {
                    position: relative;
                    text-decoration: none;
                    display: block;
                    margin: 8px auto;
                    width: max-content;

                    & i {
                        margin-right: 8px;
                        transform: scaleX(-1);
                    }

                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 0;
                        height: 1px;
                        bottom: -1px;
                        right: 0;
                        background-color: var(--green);
                        transition: all var(--standard);
                    }

                    &:hover {
                        color: var(--green);

                        &:after {
                            left: 0;
                            right: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.ht-contact-office {
    background-color: var(--green);
    height: 418px;
    color: var(--white);
    margin-top: 80px;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
        color: var(--yellow);
    }

    & a {
        color: var(--white);
    }

    & .ht-office-info {
        width: 50%;
    }

    & .ht-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    & img {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        object-fit: cover;
        object-position: left bottom;
        width: 50%;
    }
}

@media only screen and (max-width: 568px) {
    .ht-contact-cards {
        & .ht-wrapper {
            flex-direction: column;
        }

        & .ht-contact-card {
            width: 100%;
            max-width: 480px;
            margin: 0 auto 32px;
            &.ht-3-col {
                width: 100%;
            }
        }
    }

    .ht-contact-office {
        height: auto;

        & .ht-office-info {
            width: 100%;
            padding: 30px 0;
        }

        & .ht-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        & img {
            display: none;
        }
    }
}
