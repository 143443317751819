:root {
    /* radii */
    --default: 5px;
    --double: 10px;
    --round: 50%;
    --bottom: 0 0 4px 4px;
    --top: 4px 4px 0 0;

    /* shadows */
    --box-shadow: 0 1px 2px 0 var(--black-2), 0 1px 3px 0 var(--black-1);
    --box-shadow__strong: 0 3px 6px 0 var(--black-23), 0 3px 6px 0 var(--black-16);
    --box-shadow__hover: 0 6px 6px 0 var(--black-26), 0 10px 20px 0 var(--black-19);
    --box-shadow__heavy: 0 10px 10px 0 var(--black-26), 0 14px 28px 0 var(--black-25);
    --box-shadow__single: 0 2px 10px 0 var(--black-25);

    /* gradients */
    --overlay-l-to-r: linear-gradient(270deg, transparent 0%, var(--grey-34) 50%, var(--black-43) 100%);
    --overlay-r-to-l: linear-gradient(90deg, transparent 0%, var(--grey-34) 50%, var(--black-43) 100%);
    --overlay-l-to-r2: linear-gradient(270deg, transparent 0%, var(--black-70) 100%);
    --overlay-l-to-r3: linear-gradient(270deg, var(--black-70) 0%, var(--black-70) 100%);

    /* transitions */
    --standard: 250ms ease-in-out;
    --delayed: 250ms 250ms ease-in-out;
    --slow: 400ms ease-in-out;
    --longdelay: 250 400ms ease-in-out;
}

.text-center {
    text-align: center;
}
