@media only screen and (min-width: 1025px) {
    header {
        & .mobile-nav,
        & nav.mobile-nav-list {
            display: none;
        }

        & nav.desktop-nav {
            position: relative;
            display: inline-block;

            & ul:not(.sub-menu) {
                list-style: none;
                padding: 0;
                margin: 0;

                & > li {
                    display: inline-block;
                    margin: 0;
                    padding: 12px 24px;

                    & a {
                        position: relative;
                        display: inline-block;
                        color: var(--green-dark);
                        line-height: 22px;
                        text-decoration: none;
                        transition: all var(--standard);
                        font-size: 18px;
                        font-weight: var(--bold);

                        &:after {
                            position: absolute;
                            display: block;
                            content: "";
                            width: 0;
                            height: 1px;
                            bottom: -1px;
                            right: 0;
                            background-color: var(--green-dark);
                            transition: all var(--standard);
                        }

                        &:hover {
                            color: var(--green-dark);

                            &:after {
                                left: 0;
                                right: auto;
                                width: 100%;
                            }
                        }
                    }

                    &.menu-item-has-children {
                        padding: 0;

                        & > a {
                            position: relative;
                            z-index: 10;
                            padding: 12px 46px 12px 24px;
                            border-radius: 5px 5px 0 0;

                            &:before {
                                content: "\f078";
                                position: absolute;
                                top: 16px;
                                right: 24px;
                                display: inline-block;
                                margin-left: 8px;
                                color: var(--green-dark);
                                font-family: "Font Awesome 5 Pro";
                                font-size: responsive 12px 16px;
                                font-style: normal;
                                font-variant: normal;
                                font-weight: var(--bold);
                                text-rendering: auto;
                                line-height: 1;
                                transition: transform var(--standard);
                            }

                            &:after {
                                display: none;
                            }
                        }

                        &:hover {
                            & > a {
                                color: var(--white);
                                background-color: var(--green);

                                &:before {
                                    color: var(--white);
                                    transform: rotate(180deg);
                                }
                            }

                            & > ul.sub-menu {
                                height: auto;
                                background-color: var(--green);
                                border-radius: 0 5px 5px 5px;
                                opacity: 1;
                                transition: all var(--standard);

                                & > li {
                                    opacity: 1;
                                    pointer-events: all;

                                    & a {
                                        color: var(--white);

                                        &:hover {
                                            border-radius: 0;

                                            &:after {
                                                left: 0;
                                                right: auto;
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.ht-nav--main {
                    display: inline-block;
                    margin: 28px 0 0;
                }

                &.ht-nav--external {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    & li {
                        margin: 0 0 0 10px;
                        padding: 0;

                        & a {
                            font-size: responsive 14px 14px;
                            background-color: var(--green-dark);
                            width: 27px;
                            height: 27px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;

                            &:after {
                                display: none;
                            }

                            & i {
                                color: var(--white);
                            }

                            &.button {
                                margin-left: 8px;
                            }
                        }
                    }
                }
            }
        }

        & .sub-menu {
            position: absolute;
            z-index: 9;
            display: block;
            opacity: 0;
            height: 0;
            min-height: 0;
            padding: 16px 8px 32px;
            box-shadow: var(--box-shadow);
            transition: all var(--standard);

            & > li {
                display: block !important;
                padding: 0;
                min-width: 320px;
                opacity: 0;
                pointer-events: none;
                transition: all var(--standard);

                &:last-child {
                    margin: 0 auto 8px;
                }

                & a {
                    width: 100%;
                    padding: 8px 16px;
                    color: var(--white);
                    font-size: responsive 13px 17px;
                    font-weight: var(--bold);
                    line-height: 1.235;
                    transition: all var(--standard);

                    & i {
                        font-size: responsive 9px 13px;
                        margin-left: 8px;
                    }

                    &:hover {
                        background-color: var(--black-1);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    header {
        & nav.desktop-nav {
            & ul:not(.sub-menu) {
                & > li {
                    padding: 12px;

                    &.menu-item-has-children {
                        padding: 0;

                        & > a {
                            padding: 12px 24px 12px 12px;

                            &:before {
                                right: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    header {
        & nav.desktop-nav {
            display: none;
        }

        & nav.mobile-nav-list {
            position: absolute;
            z-index: 999;
            top: 120px;
            left: calc(-50vw + 406px);
            display: block;
            width: 100vw;
            height: calc(100vh - 152px);
            padding: 0;
            background-color: var(--white);
            overflow-y: auto;
            transition: var(--standard);

            &.closed {
                transform: translateX(101vw);
            }

            &.open {
                transform: translateX(0);
            }

            & ul.ht-nav--main {
                position: relative;
                width: 100%;
                padding: 0;
                margin: 0 auto;
                list-style: none;

                & li {
                    display: block;

                    & > a {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        padding: 23px 24px 22px;
                        color: var(--green-dark);
                        font-size: responsive 18px 18px;
                        font-weight: var(--medium);
                        line-height: 1.21;
                        text-decoration: none;
                        border-top: 1px solid var(--light-grey);

                        &:hover {
                            color: var(--green);
                            transition: var(--standard);
                        }
                    }

                    &:first-child {
                        & a {
                            border: none;
                        }
                    }

                    &.menu-item-has-children {
                        & > a {
                            & .toggle-sub-menu {
                                position: absolute;
                                right: 0;
                                top: 0;
                                display: block;
                                padding: 26px 24px 24px;
                                font-family: "Font Awesome 5 Pro";
                                font-size: responsive 17px 17px;
                                line-height: 1;
                                transition: all var(--standard);
                            }

                            &.open {
                                & .toggle-sub-menu {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }

            & ul.sub-menu {
                position: relative;
                top: 0;
                left: 0;
                display: none;
                height: 0;
                padding: 0;
                margin: 0;
                opacity: 0;
                list-style: none;
                transition: height var(--delayed), margin var(--delayed),
                    opacity var(--standard);

                & > li {
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                    opacity: 0;
                    pointer-events: none;
                    transition: all var(--standard);

                    & img {
                        display: none;
                    }
                }

                &.open {
                    display: block;
                    margin: 0;
                    height: auto;
                    opacity: 1;
                    background-color: var(--green-5);
                    transition: all var(--standard);

                    & > li {
                        opacity: 1;
                        pointer-events: all;

                        & a {
                            border-top: 1px solid var(--light-grey);
                            padding: 23px 24px 22px 40px;
                        }
                    }
                }
            }

            & ul.ht-nav--external {
                position: relative;
                bottom: 0;
                width: 100%;
                padding: 16px 0 40px;
                background-color: var(--white);
                list-style: none;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                gap: 12px 0;

                & > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                & li {
                    margin: 0 0 0 10px;
                    padding: 0;

                    & a {
                        font-size: responsive 14px 14px;
                        background-color: var(--green-dark);
                        width: 27px;
                        height: 27px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        text-decoration: none;

                        &:after {
                            display: none;
                        }

                        & i {
                            color: var(--white);
                        }

                        &.button {
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    header {
        & nav.mobile-nav-list {
            left: calc(-50vw + 384px);

            & ul {
                width: 100%;
                max-width: 768px;
                margin: 0 auto;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    header {
        & .mobile-nav {
            margin-top: 8.5px;
        }

        & nav.mobile-nav-list {
            left: calc(-50vw + 368px);
            top: 76px;
            padding-top: 16px;
            height: calc(100vh - 92px);

            & ul {
                width: 736px;
                margin: 0 auto;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    header {
        & nav.mobile-nav-list {
            left: calc(-50vw + 352px);

            & ul {
                width: 704px;
                margin: 0 auto;
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    header {
        & .ht-wrapper {
            max-width: calc(100vw - 32px) !important;
        }

        & nav.mobile-nav-list {
            left: -16px;

            & ul {
                width: calc(100vw - 32px);

                &.sub-menu {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    header {
        & .ht-wrapper {
            max-width: calc(100vw - 16px) !important;
        }

        & .mobile-nav {
            & .mobile-nav-toggle {
                margin-right: 8px;
            }
        }

        & nav.mobile-nav-list {
            left: -8px;

            & ul {
                width: calc(100vw - 16px);
            }
        }
    }
}
