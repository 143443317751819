.ht-breadcrumb {
    position: relative;
    z-index: 9;
    padding: 16px 0;
    width: 100%;

    & .crumbs {
        margin: 0;

        & span {
            font-size: 22px;
            color: var(--green-dark);
        }

        & a {
            color: var(--green-dark);
            font-size: responsive 12px 16px;
            font-weight: var(--regular);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        & .breadcrumb_last {
            color: var(--orange);
            font-size: responsive 12px 16px;
            font-weight: var(--regular);
        }

        & i {
            margin: 0 4px;
            color: var(--grey);
            font-size: responsive 8px 12px;
            font-weight: var(--bold);
        }
    }
}

@media only screen and (max-width: 414px) {
    .ht-breadcrumb {
        display: none;
    }
}
