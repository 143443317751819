.ht-video {
    padding: 56px 0 88px;

    & .ht-wrapper {
        & h2 {
            margin: 0 auto 40px;
            text-align: center;
        }

        & .ht-video-link {
            position: relative;
            display: block;
            width: 824px;
            height: calc(824px * 0.5625);
            margin: 0 auto;
            background-size: cover;
            border-radius: var(--default);
            box-shadow: var(--box-shadow__single);

            &:before {
                content: "";
                position: absolute;
                z-index: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--black-2);
                border-radius: var(--default);
                transition: all var(--standard);
            }

            &:after {
                content: url("../hww/img/play.png");
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 60px;
                height: 60px;
                margin: auto;
                transition: none;
                z-index: 1;
            }
        }
    }
}

.ht-inline-video {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 30px;

    & iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

@media only screen and (max-width: 1024px) {
    .ht-video {
        & .ht-wrapper {
            & .ht-video-link {
                position: relative;
                display: block;
                width: 736px;
                height: calc(736px * 0.5625);
                margin: 0 auto;
                background-size: cover;
                border-radius: var(--default);
                box-shadow: var(--box-shadow__single);
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-video {
        & .ht-wrapper {
            & .ht-video-link {
                width: calc(100vw - 48px);
                height: calc(calc(100vw - 48px) * 0.5625);
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .ht-video {
        background-size: 45%;
    }
}
