.button {
    display: inline-flex;
    padding: 6px 16px;
    font-size: responsive 14px 14px;
    line-height: 1.35714;
    text-decoration: none;
    border-radius: var(--default);
    box-shadow: var(--box-shadow);
    transition: all var(--standard);

    &.card-button {
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    &:hover {
        box-shadow: var(--box-shadow__hover);
    }

    &.button-primary {
        color: var(--white);
        background-color: var(--green);
        border: 2px solid var(--green);

        &.icon-left i {
            margin-right: 4px;
        }

        &.icon-right i {
            margin-left: 4px;
        }
    }

    &.button-yellow {
        padding: 9px 24px 10px;
        color: var(--green);
        font-size: responsive 12px 16px;
        font-weight: var(--bold);
        line-height: 30px;
        background-color: var(--yellow);
        border: none;

        & i {
            margin-right: 16px;
            font-size: 30px;
            line-height: 1;
        }

        &.button-apply {
            margin-top: 32px;

            & i {
                margin: 0 0 0 8px;
                font-size: responsive 18px 18px;
                font-weight: var(--bold);
                line-height: 30px;
            }
        }

        &.button-alert {
            margin-top: 16px;
        }
    }

    &.button-external {
        padding: 12px 20px;
        margin: 0 0 0 16px;
        color: var(--green-dark);
        font-weight: var(--bold);
        background-color: var(--mid-grey);
        border: none;
        border-radius: 40px;
        box-shadow: none;

        & i {
            margin: 2px 0 0 8px;
        }
    }

    &.button-cta {
        display: inline-block;
        min-width: 256px;
        padding: 16px 15px;
        border: none;
        border-radius: 5px;
        background-color: var(--yellow);
        box-shadow: none;

        & span {
            display: block;
            width: 100%;
            color: var(--green);
            font-size: responsive 12px 18px !important;
            font-range: 1024px 1366px;
            font-weight: var(--bold);
            line-height: 1.33333;
            text-align: center;
            margin: 0;

            &:last-child {
                color: var(--white);
            }
        }
    }

    &.button-visual {
        display: inline-flex;
        padding: 0;
        border: none;
        border-radius: 5px;
        background-color: var(--green);
        box-shadow: none;

        & span {
            display: inline-flex;
            color: var(--white);
            font-size: responsive 15px 17px !important;
            font-range: 1024px 1366px;
            font-weight: var(--bold);
            line-height: 1.35294;
            margin: 0;

            &:first-child {
                padding: 24px 8px 22px 16px;
                text-align: left;
                white-space: nowrap;
            }

            &:last-child {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 48px;
                padding: 25px 16px;
                margin: 0 0 0 auto;
                font-size: responsive 13px 19px;
                font-range: 1024px 1366px;
                line-height: 1;
                text-align: center;
                background-color: var(--black-1);
                border-radius: 0 5px 5px 0;
            }
        }
    }
}

@keyframes phone-icon {
    0% {
        transform: rotate(0deg) scaleX(-1);
    }
    2% {
        transform: rotate(-5deg) scaleX(-1);
    }
    4% {
        transform: rotate(5deg) scaleX(-1);
    }
    6% {
        transform: rotate(-5deg) scaleX(-1);
    }
    8% {
        transform: rotate(5deg) scaleX(-1);
    }
    10% {
        transform: rotate(-5deg) scaleX(-1);
    }
    12% {
        transform: rotate(5deg) scaleX(-1);
    }
    14% {
        transform: rotate(-5deg) scaleX(-1);
    }
    16% {
        transform: rotate(5deg) scaleX(-1);
    }
    18% {
        transform: rotate(-5deg) scaleX(-1);
    }
    20% {
        transform: rotate(5deg) scaleX(-1);
    }
    22% {
        transform: rotate(-5deg) scaleX(-1);
    }
    24% {
        transform: rotate(5deg) scaleX(-1);
    }
    26% {
        transform: rotate(-5deg) scaleX(-1);
    }
    28% {
        transform: rotate(5deg) scaleX(-1);
    }
    30% {
        transform: rotate(-5deg) scaleX(-1);
    }
    32% {
        transform: rotate(5deg) scaleX(-1);
    }
    34% {
        transform: rotate(-5deg) scaleX(-1);
    }
    36% {
        transform: rotate(5deg) scaleX(-1);
    }
    38% {
        transform: rotate(-5deg) scaleX(-1);
    }
    40% {
        transform: rotate(5deg) scaleX(-1);
    }
    42% {
        transform: rotate(-5deg) scaleX(-1);
    }
    44% {
        transform: rotate(5deg) scaleX(-1);
    }
    46% {
        transform: rotate(0deg) scaleX(-1);
    }
}

@-ms-keyframes phone-icon {
    0% {
        transform: rotate(0deg) scaleX(-1);
    }
    2% {
        transform: rotate(-5deg) scaleX(-1);
    }
    4% {
        transform: rotate(5deg) scaleX(-1);
    }
    6% {
        transform: rotate(-5deg) scaleX(-1);
    }
    8% {
        transform: rotate(5deg) scaleX(-1);
    }
    10% {
        transform: rotate(-5deg) scaleX(-1);
    }
    12% {
        transform: rotate(5deg) scaleX(-1);
    }
    14% {
        transform: rotate(-5deg) scaleX(-1);
    }
    16% {
        transform: rotate(5deg) scaleX(-1);
    }
    18% {
        transform: rotate(-5deg) scaleX(-1);
    }
    20% {
        transform: rotate(5deg) scaleX(-1);
    }
    22% {
        transform: rotate(-5deg) scaleX(-1);
    }
    24% {
        transform: rotate(5deg) scaleX(-1);
    }
    26% {
        transform: rotate(-5deg) scaleX(-1);
    }
    28% {
        transform: rotate(5deg) scaleX(-1);
    }
    30% {
        transform: rotate(-5deg) scaleX(-1);
    }
    32% {
        transform: rotate(5deg) scaleX(-1);
    }
    34% {
        transform: rotate(-5deg) scaleX(-1);
    }
    36% {
        transform: rotate(5deg) scaleX(-1);
    }
    38% {
        transform: rotate(-5deg) scaleX(-1);
    }
    40% {
        transform: rotate(5deg) scaleX(-1);
    }
    42% {
        transform: rotate(-5deg) scaleX(-1);
    }
    44% {
        transform: rotate(5deg) scaleX(-1);
    }
    46% {
        transform: rotate(0deg) scaleX(-1);
    }
}
