header {
    background-color: var(--white);
    padding: 26px 0;

    & .ht-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & .ht-header--logo {
            display: inline-block;
            width: 141px;
            height: auto;	
        }
    }
}
form.mobileSearch {
    margin-top:20px;
}
form.mobileSearch input[type=submit]{
    background-color:#00826d;
    border:0px;
    border-radius: 5px;
    font-size:14px;
    color:white;
    padding:4px 8px;
}
form.searchMenu{
    display: inline-flex;
}
form.searchMenu input[type=submit]{
    padding:4px 8px;
    background-color: transparent;
    border:1px solid transparent;
    color:white;
    outline: none; 
    border-radius: 5px;
    font-size:14px;
    margin-left:-60px;
}
.search-jquery-input{
    padding:4px 8px;
    background-color: white;
    border:1px solid rgba(0,0,0,.1);
    color:black;
    margin-right:10px;
    outline: none; 
    border-radius: 5px;
    font-size:14px;
}
#searchTrigger{
    margin-left:-25px;
    margin-top: 4px;
}

.search-jquery-input::placeholder{
    color:rgba(0, 0, 0, 0.5);
}
.search-jquery-input:-ms-input-placeholder {
    color:rgba(0, 0, 0, 0.5);
}
.search-jquery-input::-ms-input-placeholder{
    color:rgba(0, 0, 0, 0.5);
}

.search-jquery-results {
    padding: 10px;
    /* position: absolute; */
    background-color: #f9f9f9;
    padding: 12px 16px;
    z-index: 1;
}

.search-jquery-results {
    display: none;
}

.search-jquery-results ul {
    list-style-type:none;
}

.search-jquery-results li {
    padding-bottom: 10px;
}

.search-jquery-results li a {
    text-decoration: none;
}

@media only screen and (max-width: 1024px) {
    header {
        z-index: 998;
        
        & .mobile-nav {
            margin-top: 18px;
            
            & a.mobile-nav-toggle {
                font-size: 32px;
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    header {
        padding: 16px 0;

        & .ht-header {

            & .ht-header--logo {
                width: auto;
                height: 60px;
                margin: 0;

                & img {
                    width: auto;
                    height: 60px;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    
}

@media only screen and (max-width: 736px) {
    
}


@media only screen and (max-width: 414px) {
    
}
