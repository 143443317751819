.ht-header--visual {
    position: relative;
    width: 100%;

    & .ht-wrapper {
        z-index: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    & h1,
    .h1,
    & h2 {
        margin: 0 0 40px;
        color: var(--white);
        text-align: center;
        hyphens: auto;
    }

    & .ht-button--row {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        gap: 20px;

        & .button-visual {
            width: 240px;
        }
    }

    & .button.button-cta {
        position: absolute;
        top: -40px;
        right: 0;
    }

    &.ht-header--visual-content {
        height: auto;
        background-color: var(--green-light);

        & h1 {
            margin: 0;
        }

        &:after {
            display: none;
        }

        &:not(.with-visual) {
            padding: 67px 0 66px;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &.with-visual {
            height: 295px;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 0;
                height: 0;
                border-top: 295px solid var(--green-light);
                border-right: 141px solid transparent;
                transition: none;
                z-index: 1;
            }

            & img {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                object-fit: cover;
                object-position: center top;
                width: 50%;
            }

            & h1,
            .h1,
            & h2 {
                margin: 0;
                color: var(--yellow);
                text-align: left;
                max-width: 50%;
            }
        }
    }

    &.without--image {
        height: 39vw;
        max-height: 536px;
        padding: 56px 0;
        background-size: cover;
        background-position: center top;
        background-image: url("/wp-content/themes/hww/img/background.png");

        &:after {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 49.5%;
            opacity: 0.75;
            background: linear-gradient(
                180deg,
                transparent 0,
                rgba(0, 0, 0, 1) 100%
            );
        }
    }
}

@media only screen and (max-width: 736px) {
    body.home {
        & .ht-header--visual {
            &:after {
                height: 19.5vw;
                top: 19.8vw;
            }

            & .button.button-cta {
                top: -272px;
            }

            & h2 {
                margin: 0 0 28px;
                font-size: responsive 26px 32px;
            }

            & h1 {
                margin: 0 0 24px;
            }

            & .ht-button--row {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .ht-header--visual {
        height: auto;
        padding: 32px 0;
        background-size: cover;
        background-repeat: no-repeat;
        border-bottom: 1px solid var(--light-grey);

        &:after {
            height: 50%;
            top: 50%;
            bottom: auto;
        }

        & h1 {
            margin: 0;
            color: var(--white);
        }

        &.ht-header--visual-content {
            padding: 32px 0;
            height: auto;
            min-height: 0;

            & h1 {
                margin: 0;
            }

            &:after {
                display: none;
            }

            &.with-visual {
                &:after {
                    display: block;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    body.home {
        & .ht-header--visual {
            & .ht-button--row {
                & .button.button-visual {
                    width: 100%;
                }
            }
        }
    }

    .ht-header--visual.ht-header--visual-content.with-visual {
        height: auto;

        & h1 {
            padding: 40px 0;
            max-width: unset;
        }

        & img,
        &::before {
            display: none;
        }
    }
}
