.ht-post-content__share-icons {

    &.ht-post-content__share-icons--vertical {
        position: absolute;
        top: 40px;
        right: 40px;
        background-color: var(--white);
        border-radius: var(--default);
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.1);

        &.fixed {
            position: fixed;
            top: 40px;
        }
    }

    &.ht-post-content__share-icons--horizontal {
        max-width: 672px;
        margin: 0 auto;

        & span.share-text {
            position: relative;
            display: inline-block;
            margin-right: 16px;
            text-indent: 40px;

            &:after {
                content: '';
                position: absolute;
                top: 12px;
                left: 0;
                display: block;
                width: 32px;
                height: 2px;
                background-color: var(--green);
            }
        }

        & .share-links {
            display: inline-block;

            & a {
                display: inline-block;
                padding: 0;
                margin-right: 12px;
                font-size: responsive 16px 20px;

                &:last-child {
                    padding: 0;
                    margin-right: 0;
                }
            }
        }
    }

    & .share-links {
        & a {
            display: block;
            max-height: 54px;
            padding: 16px 16px 0 16px;
            font-size: responsive 20px 24px;
            color: var(--green);
            cursor: pointer;
            transition: all 250ms ease-in-out;

            &:last-child {
                padding: 16px;
            }

            &:hover {
                color: var(--green);
            }
        }
    }
}

@media only screen and (max-width: 1023px) {
    .ht-post-content__share-icons {
        &.ht-post-content__share-icons--vertical {
            display: none;
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-post-content__share-icons {
        width: calc(100vw - 48px);
        margin: 0 auto;

        &.ht-post-content__share-icons--horizontal {
            max-width: 100%;
        }
    }
}
