footer {
    padding: 56px 0 48px;
    background-color: var(--green-dark);

    & .ht-footer {
        display: flex;
        align-items: flex-start;

        & a {
            color: var(--white);
            font-size: responsive 16px 16px;
        }

        & .ht-footer--logo {
            display: inline-block;
            text-decoration: none;

            & p {
                margin: 0 0 8px;
                font-size: responsive 16px 16px;
                line-height: 1.375;
            }

            & img {
                width: 139px;
                height: auto;
                margin: 0 0 28px;
            }
        }

        & .ht-footer--address {
            color: var(--white);
            font-size: responsive 16px 16px;
            line-height: 1.375;
        }

        & .ht-footer--content {
            display: flex;
            gap: 140px;
            margin: auto;
        }

        & .ht-footer--links {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 0;
            margin: 0;
            color: var(--white);
            list-style: none;

            & li {
                margin: 0 0 8px;

                & a {
                    position: relative;
                    font-size: responsive 16px 16px;
                    line-height: 1.375;
                    text-decoration: none;
                    transition: all var(--standard);

                    &:after {
                        position: absolute;
                        display: block;
                        content: "";
                        width: 0;
                        height: 1px;
                        bottom: -1px;
                        right: 0;
                        background-color: var(--white);
                        transition: all var(--standard);
                    }

                    &:hover {
                        color: var(--white);

                        &:after {
                            left: 0;
                            right: auto;
                            width: 100%;
                        }
                    }
                }
            }
        }

        & .ht-footer--address {
            & li:first-child {
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    footer {
        & .ht-footer {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            & .ht-footer--address {
                width: 100%;
            }

            & .ht-footer--links {
                & li {
                    margin: 0 auto 8px;
                    width: 116px;
                }
            }

            & .ht-footer--logo,
            & .ht-footer--address,
            & .ht-footer--links {
                 width: 100%;
            }
            
             & .ht-footer--content {
                gap: 40px;
             }
        }
    }
}

@media only screen and (max-width: 568px) {
    footer {
        & .ht-footer {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & .ht-footer--logo,
            & .ht-footer--links,
            & .ht-footer--address {
                width: 100%;
                text-align: center;
            }

            & .ht-footer--logo,
            & .ht-footer--links {
                margin: 0 auto 74px;
            }

            & .ht-footer--logo {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            & .ht-footer--links {
                & li {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 568px) {
    footer .ht-footer {
        & .ht-footer--content {
            gap: 0;
            flex-direction: column;
        }

        & .ht-footer--links {
            margin-bottom: 40px;
        }
    }
}
