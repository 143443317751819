.ht-faq {
    padding: 32px 0;
    
    & .ht-faq--item {
        border-bottom: 1px solid var(--light-grey);

        &:last-child {
            border: none;
        }

        & .q {
            padding: 16px 30px 16px 8px;

            & h4 {
                position: relative;
                display: block;
                margin: 0;
                cursor: pointer;

                &:before {
                    content: '\f078';
                    position: absolute;
                    top: 4px;
                    right: -22px;
                    display: inline-block;
                    margin-left: 8px;
                    color: var(--green);
                    font-family: "Font Awesome 5 Pro";
                    font-size: responsive 12px 16px;
                    font-style: normal;
                    font-variant: normal;
                    font-weight: var(--bold);
                    text-rendering: auto;
                    line-height: 1;
                    transition: transform var(--standard);
                }
            }
        }

        & .a {
            & > * {
                padding: 0 8px 16px;
            }

            & ol {
                padding: 0 8px 16px 34px;

                &:last-child {
                    margin: 0;
                }
            }

            & ul {
                padding: 0 8px 16px 34px;
                margin: 0 0 24px;
                text-align: left;
                list-style: none;

                &:last-child {
                    margin: 0;
                }

                & li {
                    position: relative;
                    width: 100%;
                    margin: 0 0 8px;

                    &:before {
                        content: '\f00c';
                        position: absolute;
                        top: 2px;
                        left: -24px;
                        color: var(--yellow);
                        font-family: 'Font Awesome 5 Pro';
                    }

                    & a {
                        position: relative;
                        display: inline-block;
                        color: var(--black);
                        line-height: 22px;
                        text-decoration: none;
                        transition: all var(--standard);

                        &:after {
                            position: absolute;
                            display: block;
                            content: '';
                            width: 0;
                            height: 1px;
                            bottom: -1px;
                            right: 0;
                            background-color: var(--green);
                            transition: all var(--standard);
                        }

                        &:hover {
                            color: var(--green);

                            &:after {
                                left: 0;
                                right: auto;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        &.open {
            & .q {
                & h4 {
                    &:before {
                        transform: rotateZ(180deg);
                    }
                }
            }

            & .a {
                opacity: 1;
                height: auto;
                transition: all 250ms 250ms ease-in-out;
            }
        }

        &.closed {
            & .q {
                & h4 {
                    &:before {
                        transform: rotateZ(0deg);
                    }
                }
            }

            & .a {
                opacity: 0;
                height: 0;
                transition: all var(--standard);
            }
        }
    }
}
