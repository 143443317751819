.ht-intro--home {
    position: relative;
    overflow: hidden;
    min-height: 500px;

    & .ht-intro--image {
        position: absolute;
        left: 0;
        height: 100%;
        width: 50%;
        object-fit: cover;
        object-position: center;
    }

    & .ht-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 500px;

        & .ht-intro--text {
            display: block;
            width: 45%;
            padding: 56px 0;
            margin: 0 0 0 auto;

            & h2 {
                margin: 0 0 24px;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-intro--home {
        overflow: hidden;

        & .ht-intro--image {
            position: relative;
            width: 100vw;
            margin-right: 0;
            max-width: unset;
            height: 300px;
        }

        & .ht-wrapper {
            flex-direction: column;


            & .ht-intro--text {
                display: block;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-intro--home {
        & .ht-wrapper {
            & .ht-intro--image {
                height: 225px;
            }
        }
    }
}
