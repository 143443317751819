@import url("https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@100;200;300;600;700;800;900&display=swap");
.ht-banner {
    background-color: var(--green);

    .ht-banner-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
        gap: 1rem;

        h3 {
            font-size: 32px;
            color: var(--white);
            margin: 0;
            font-weight: 900;
            position: relative;
            text-transform: uppercase;
            font-family: Saira Extra Condensed;

            @media only screen and (max-width: 736px) {
                font-size: 24px;
            }
        }

        .button.button-visual.is--secondary {
            background-color: var(--yellow);
        }

        .button.button-visual.is--secondary span {
            color: var(--green-dark);
        }
    }
}
