.ht-vacancy-filter {
    width: 304px;

    & .filter-button {
        display: none;
    }

    & h3 {
        margin: 0 0 8px;
    }


    & ul {
        margin: 0 0 48px;
        padding: 0;
        list-style: none;

        & > li {
            margin: 0;

            & > input {
                margin: 0;
            }

            & > label {
                position: relative;
                display: block;
                padding: 0 36px 0 27px;
                margin: 0 0 7px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                & > span {
                    position: absolute;
                    right: 16px;
                    font-size: responsive 11px 15px;
                    line-height: 1.8;
                }
            }

            & > ul {
                margin: 6px 0 17px;
                padding: 0 0 0 24px;

                & > li {
                    & > label {
                        & > span {
                            position: absolute;
                            right: 16px;
                            color: var(--dark-grey);
                            font-size: responsive 9px 13px;
                            line-height: 2.07692;
                        }
                    }
                }
            }

            &.toggled>label:before {
                content: '\f00c' !important;
                color: var(--yellow);
                font-family: var(--icons);
                font-size: 16px;
                font-style: normal;
                font-weight: var(--bold);
                line-height: 0.7;
                text-transform: none;
                letter-spacing: normal;
            }
        }

        & li {
            position: relative;

            & input[type='checkbox'] {
                position: absolute;
                display: inline-block;
                width: 16px;
                height: 16px;
                padding: 0;
                margin: 0;
                cursor: pointer;
                visibility: hidden;
                outline: none;
                opacity: 1;
                -webkit-appearance: none !important;
                -moz-appearance: none !important;
                appearance: none !important;
                outline: none;

                &+label:before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 5px;
                    left: 0;
                    width: 16px;
                    height: 16px;
                    padding: 0 0 2px 2px;
                    border: 1px solid var(--checkbox);
                    border-radius: 2px;
                    word-wrap: normal;
                    white-space: nowrap;
                    vertical-align: 0;
                }

                &:checked+label:before {
                    content: '\f00c';
                    color: var(--yellow);
                    font-family: var(--icons);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: var(--bold);
                    line-height: 0.7;
                    text-transform: none;
                    letter-spacing: normal;
                }
            }
        }

        &:last-child {
            margin: 0;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .ht-vacancy-filter {
        width: 30.5835%;
    }
}

@media only screen and (max-width: 736px) {
    .ht-vacancy-filter {
        width: 100%;
        margin: -16px 0 32px;
        background-color: var(--white);
        border-radius: var(--default);
        box-shadow: var(--box-shadow);
        transition: all var(--standard);

        .filter-button {
            display: flex;
            align-items: center;
            padding: 24px;
            color: var(--green);
            font-weight: var(--bold);
            cursor: pointer;

            & i.fa-tasks {
                margin-right: 8px;
            }

            & i.fa-chevron-down {
                margin: 0 0 0 auto;
                transform: rotate(0);
                transition: all var(--standard);
            }

            &.upsidedown {
                & i.fa-chevron-down {
                    transform: rotate(180deg);
                }
            }
        }

        & form {
            padding: 0 24px 24px;

            &.open {
                display: block;
                height: auto;
                opacity: 1;
            }

            &.closed {
                display: none;
                height: 0;
                opacity: 0;
            }
        }
    }
}
