.ht-page__contact-page-form {
    & > div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
        max-width: 848px;
        margin: 0 auto;
        padding: 48px 0 32px;

        & h2 {
            margin: 48px auto 32px;
        }

        & form {
            width: 100%;
            max-width: none;
        }

        & .gform_wrapper {
            margin: 0;
            width: 100%;
            max-width: none;

            & ul.gform_fields {
                & li.gfield {
                    & .gfield_label {
                        color: var(--black);
                        font-weight: var(--medium);

                        & .gfield_required {
                            color: var(--black);
                        }
                    }

                    & .ginput_container {
                        & .large {
                            width: 100%;
                            max-width: none;
                            border-bottom: 1px solid var(--black);
                        }

                        & .medium {
                            width: 100%;
                            max-width: none;
                            border-bottom: 1px solid var(--black);
                        }

                        & .textarea {
                            min-height: 29px;

                            &.large {
                                height: 29px;
                            }
                        }

                        &.ginput_container_textarea {
                            height: 51px;
                        }

                        .hiddendiv {
                            display: none;
                            white-space: pre-wrap;
                            width: 100%;
                            min-height: 29px;
                            height: 29px;
                            font-family: var(--primary);
                            font-size: responsive 12px 16px;
                            line-height: 1.5;
                            font-weight: var(--medium);
                            word-wrap: break-word;
                            border: 0;
                        }
                    }

                    &.phone,
                    &.email {
                        display: inline-block;
                        width: calc(50% - 16px);

                        &.gfield_error {
                            & .gfield_description.validation_message {
                                bottom: 5px !important;
                            }
                        }
                    }

                    &.email {
                        margin-right: 32px !important;
                    }

                    &.privacy-consent {
                        margin-right: 32px !important;
                    }

                    &.newsletter-consent,
                    &.privacy-consent {
                        display: inline-block;
                        width: 100%;
                        vertical-align: top;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;

                        &.gfield_error {
                            & .gfield_description.validation_message {
                                bottom: 8px !important;
                            }
                        }

                        & .gfield_required {
                            display: none;
                        }

                        & .gfield_consent_label {
                            display: inline-block;
                            width: calc(100% - 24px);
                            max-width: none;
                            padding-left: 24px;
                            margin: 12px 0 0;
                            color: var(--black);
                            font-size: responsive 10px 12px;
                            line-height: 1.66667;
                            cursor: pointer;

                            & a {
                                color: var(--green);
                                font-size: responsive 12px 14px;
                                line-height: responsive 16px 20px;
                            }
                        }

                        & input[type="checkbox"] {
                            -webkit-appearance: none !important;
                            -moz-appearance: none !important;
                            appearance: none !important;
                            outline: none;
                            margin-top: 10px;
                            vertical-align: top;

                            &::before {
                                color: var(--dark-grey);
                            }
                        }
                    }
                }
            }
        }

        & .gform_footer {
            display: inline-block;
            max-width: 20%;
            position: relative;
            padding: 0;
            text-align: left;

            & input[type="submit"].button {
                margin-right: 0;
            }
        }

        & .ht-page__contact-form--marketeer {
            display: none;
        }
    }

    & p {
        margin: 0 0 24px;
        font-family: var(--primary);
        font-size: responsive 12px 14px;
        line-height: responsive 16px 20px;
        color: var(--grey);
    }
}

.ht-page__contact-page-form {
    & .gform_wrapper {
        margin: 0;

        & ul.gform_fields {
            & li.gfield {
                position: relative;
                padding: 0;
                margin: 0;

                & .gfield_label {
                    position: absolute;
                    top: 21px;
                    left: 2px;
                    color: var(--black);
                    font-weight: var(--medium);
                    transition: all var(--standard);

                    & .gfield_required {
                        color: var(--black);
                    }

                    &.focus {
                        color: var(--green);
                    }

                    &.not-empty {
                        top: 0;
                        font-size: 11px;
                    }
                }

                & .ginput_container {
                    margin: 0 0 24px;

                    & .large {
                        width: 100%;
                        max-width: 496px;
                        padding: 0 2px !important;
                        margin: 22px 0 0;
                        border: none;
                        border-radius: 0;
                        background-color: transparent;
                        transition: all var(--standard);

                        &:focus {
                            outline: none;
                            border-bottom: 1px solid var(--green);
                        }
                    }

                    & .medium {
                        width: calc(50% - 16px);
                        padding: 0 2px !important;
                        margin: 22px 0 0;
                        border: none;
                        border-radius: 0;
                        background-color: transparent;
                        transition: all var(--standard);

                        &:focus {
                            outline: none;
                            border-bottom: 1px solid var(--green);
                        }
                    }
                }

                &.newsletter-consent,
                &.privacy-consent {
                    position: relative;

                    & .gfield_consent_label {
                        padding-left: 24px;
                    }

                    & input[type="checkbox"] {
                        position: absolute;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        padding: 0;
                        margin: 0;
                        cursor: pointer;
                        visibility: hidden;
                        outline: none;
                        opacity: 1;
                        -webkit-appearance: none !important;
                        -moz-appearance: none !important;
                        appearance: none !important;
                        outline: none;

                        & + label:before {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            top: 14px;
                            left: 0;
                            width: 16px;
                            height: 16px;
                            padding: 0 0 2px 2px;
                            border: 1px solid var(--checkbox);
                            border-radius: 2px;
                            word-wrap: normal;
                            white-space: nowrap;
                            vertical-align: 0;
                        }

                        &:checked + label:before {
                            content: "\f00c";
                            color: var(--yellow);
                            font-family: var(--icons);
                            font-size: 16px;
                            font-style: normal;
                            font-weight: var(--bold);
                            line-height: 0.7;
                            text-transform: none;
                            letter-spacing: normal;
                        }
                    }
                }
            }
        }
    }

    & .gform_footer {
        padding: 0;
        margin: 0;

        & input[type="submit"].button {
            display: inline-flex;
            padding: 9px 24px 10px;
            color: var(--white);
            font-family: var(--primary);
            font-size: responsive 12px 16px;
            font-weight: var(--bold);
            line-height: 30px;
            cursor: pointer;
            text-decoration: none;
            border-radius: var(--default);
            border: 2px solid var(--green);
            background-color: var(--green);
            transition: all var(--standard);
            box-shadow: none;

            &:hover {
                box-shadow: var(--box-shadow__hover);
            }
        }
    }
}

.ht-wrapper {
    & .gform_wrapper {
        & div.validation_error {
            padding: 0;
            color: var(--error);
            border: none;
            text-align: left;
        }

        &.gform_validation_error {
            & .gform_body {
                & ul {
                    & li.gfield {
                        & .gfield_label {
                            color: var(--black);
                            font-weight: var(--medium);

                            & .gfield_required {
                                color: var(--black);
                            }
                        }

                        &.gfield_error {
                            border: none;
                            background-color: transparent;
                            max-width: none !important;
                            margin: 0 !important;

                            &:not(.gf_left_half):not(.gf_right_half) {
                                max-width: none !important;
                            }

                            &.gfield_contains_required {
                                margin: 0;

                                & .gfield_label {
                                    margin: 0;
                                }

                                & .gfield_description.validation_message {
                                    position: absolute;
                                    bottom: -19px;
                                    padding: 0;
                                    color: var(--error);
                                    font-size: responsive 9px 11px;
                                    font-weight: var(--light);
                                }

                                & .ginput_container {
                                    margin-top: 0;

                                    & input {
                                        margin-top: 22px;

                                        &:not([type="radio"]):not(
                                                [type="checkbox"]
                                            ):not([type="submit"]):not(
                                                [type="button"]
                                            ):not([type="image"]):not(
                                                [type="file"]
                                            ) {
                                            border: none;
                                            border-bottom: 1px solid
                                                var(--error);
                                            border-radius: 0;
                                        }
                                    }
                                }

                                &.newsletter-consent,
                                &.privacy-consent {
                                    -webkit-appearance: none;
                                    -moz-appearance: none;
                                    appearance: none;

                                    & input[type="checkbox"] {
                                        -webkit-appearance: none !important;
                                        -moz-appearance: none !important;
                                        appearance: none !important;
                                        outline: none;

                                        &:before {
                                            color: var(--error);
                                        }
                                    }
                                }
                            }
                        }

                        &.voornaam,
                        &.email {
                            margin-right: 32px !important;
                        }
                    }
                }
            }
        }
    }
}

.hiddendiv {
    position: fixed;
    z-index: -1;
    white-space: pre-wrap;
    width: 100%;
    min-height: 29px;
    max-height: 130px;
    font-family: var(--primary);
    font-size: responsive 12px 16px;
    line-height: 1.5;
    font-weight: var(--medium);
    word-wrap: break-word;
    border: 0;
}

@media only screen and (max-width: 876px) {
    .ht-page__contact-page-form {
        & > div {
            width: calc(100% - 32px);
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-page__contact-page-form {
        & > div {
            width: calc(100% - 32px);
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 640px) {
    .ht-page__contact-page-form {
        ul.gform_fields li.gfield.privacy-consent .gfield_consent_label,
        ul.gform_fields li.gfield.newsletter-consent .gfield_consent_label {
            width: calc(100% - 24px);
        }

        & .gform_footer {
            & input[type="submit"].button {
                width: auto;
                min-height: 0;
            }
        }
    }

    .hiddendiv {
        position: fixed;
        z-index: -1;
        white-space: pre-wrap;
        width: 100%;
        min-height: 52px;
        max-height: 130px;
        font-family: var(--primary);
        font-size: responsive 12px 16px;
        line-height: 1.5;
        font-weight: var(--medium);
        word-wrap: break-word;
        border: 0;
    }
}

@media only screen and (max-width: 600px) {
    .ht-page__contact-form {
        background-size: initial;
        background-color: var(--green);
        background-image: none !important;

        & .ht-page__contact-form--marketeer {
            display: none;
        }

        & > div {
            max-width: 100%;
            padding: 76px 0 24px;

            & form {
                width: 100%;
                max-width: none;
                margin: 0 0 120px;
            }
        }
    }

    .ht-page__contact-page-form {
        & .gform_wrapper {
            & ul.gform_fields {
                & li.gfield {
                    &.voornaam,
                    &.achternaam,
                    &.phone,
                    &.email {
                        width: 100% !important;
                        margin-right: 0 !important;

                        & .ginput_container {
                            & .medium {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .ht-page__contact-page-form {
        & > div {
            & .gform_footer {
                display: block;
                max-width: none;
                position: relative;
                top: auto;
                right: auto;
                bottom: 0;
                padding: 0;
                text-align: left;

                & input[type="submit"].button {
                    margin-right: 0;
                }
            }

            & .gform_wrapper {
                & ul.gform_fields {
                    & li.gfield {
                        & .gfield_label {
                            color: var(--black);
                            font-weight: var(--medium);

                            & .gfield_required {
                                color: var(--black);
                            }
                        }

                        &.newsletter-consent,
                        &.privacy-consent {
                            display: block;
                            width: 100%;
                            max-width: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                        }
                    }
                }
            }
        }
    }
}
