header,
footer,
section,
article {
    position: relative;
    width: 100vw;
    margin: 0 auto;

    & .ht-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 1536px;
        margin: 0 auto;
        padding: 0 16px;
    }
}

#vacature .slbContent,
#vacature .slbIframe.application-form {
    width: 520px;
    height: 90vh;
    max-height: 820px;
    background-color: var(--white);
}

@media only screen and (min-width: 1280px) and (max-width: 1535px) {
    header,
    footer,
    section,
    article {
        & .ht-wrapper {
            max-width: 1280px;
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    header,
    footer,
    section,
    article {
        & .ht-wrapper {
            max-width: 1024px;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    header,
    footer,
    section,
    article {
        & .ht-wrapper {
            max-width: 768px;
        }
    }
}

@media only screen and (min-width: 640px) and (max-width: 767px) {
    header,
    footer,
    section,
    article {
        & .ht-wrapper {
            max-width: 640px;
        }
    }
}

@media only screen and (max-width: 639px) {
    header,
    footer,
    section,
    article {
        & .ht-wrapper {
            max-width: 100%;
        }
    }

    #vacature .slbContent,
    #vacature .slbIframe.application-form {
        width: calc(100vw - 32px);
    }
}
