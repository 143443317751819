.ht-vacancies {
    margin: 0 0 112px;

    & .ht-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        & > * {
            position: relative;
            z-index: 1;
        }

        & .ht-filter--toggle {
            display: none;
        }

        & .ht-vacancy-search {
            width: 100%;
            margin: 0 0 40px;

            & form {
                display: flex;
                
                & input {
                    display: inline-block;
                    width: 80%;
                    height: 50px;
                    padding: 14px 24px 13px;
                    margin: 0;
                    font-size: responsive 12px 16px;
                    line-height: 1.375;
                    background-color: var(--near-white);
                    border: none;
                    border-radius: 5px 0 0 5px;
                    appearance: none;
                }

                & button {
                    display: inline-block;
                    width: 20%;
                    height: 50px;
                    padding: 12px 0 11px;
                    margin: 0;
                    color: var(--green);
                    font-size: responsive 15px 19px;
                    font-weight: var(--bold);
                    line-height: 1.36842;
                    text-align: center;
                    background-color: var(--yellow);
                    border: none;
                    border-radius: 0 5px 5px 0;
                    appearance: none;
                }
            }
        }
    }
}

.ht-vacancy-header {
    padding: 16px 0;
    margin: 0 0 32px;
    border-top: 1px solid var(--light-grey);
    border-bottom: 1px solid var(--light-grey);

    & p {
        margin: 0;

        & span {
            color: var(--green);
            font-weight: var(--bold);
        }

        & a {
            color: var(--black);
            text-decoration: none;
            cursor: default;
        }
    }
}

.ht-vacancy-content {
    padding-bottom: 56px;

    &:not(.steady) > div,
    &:not(.steady) > p {
        margin: 0;

        & br {
            display: none;
        }

        & > b,
        & > strong {
            display: block;
            color: var(--green);
            font-size: responsive 18px 22px;
            line-height: 1.36363;
            margin: 16px 0;
        }

        &:first-child {
            font-size: responsive 14px 18px;
            line-height: 1.33333;
            font-weight: var(--light);
            font-style: italic;
            margin-bottom: 0;

            & > b,
            & > strong {
                display: block;
                margin: 24px 0;
                color: var(--green);
                font-size: responsive 23px 31px;
                line-height: 1.35483;
                font-style: normal;
                font-weight: var(--bold);
            }
        }
    }

    &:not(.steady) ul {
        padding: 0 0 0 26px;
        margin: 0 0 24px;
        text-align: left;
        list-style: none;

        & li {
            position: relative;
            width: 100%;
            margin: 0 0 8px;

            &:before {
                content: '\f00c';
                position: absolute;
                top: 2px;
                left: -24px;
                color: var(--yellow);
                font-family: 'Font Awesome 5 Pro';
            }

            & a {
                position: relative;
                display: inline-block;
                color: var(--black);
                line-height: 22px;
                text-decoration: none;
                transition: all var(--standard);

                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 0;
                    height: 1px;
                    bottom: -1px;
                    right: 0;
                    background-color: var(--green);
                    transition: all var(--standard);
                }

                &:hover {
                    color: var(--green);

                    &:after {
                        left: 0;
                        right: auto;
                        width: 100%;
                    }
                }
            }

            & > ul {
                margin-top: 8px;
                
                & > li {
                    &:before {
                        color: var(--green);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .ht-vacancies {
        & .ht-wrapper {
            &:after {
                height: calc(876px * 1.15591);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-vacancies {
        & .ht-wrapper {
            &:after {
                height: calc(812px * 1.15591);
            }
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-vacancies {
        & .ht-wrapper {
            &:after {
                height: calc(768px * 1.15591);
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-vacancies {
        & .ht-wrapper {
            &:after {
                height: calc(736px * 1.15591);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-vacancies {
        & .ht-wrapper {
            &:after {
                height: calc(704px * 1.15591);
            }
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-vacancies {
        & .ht-wrapper {
            &:after {
                height: calc(calc(100vw - 48px) * 1.15591);
            }

            & .ht-filter--toggle {
                display: block;
                width: 100%;
                margin: 0 0 32px 0;

                & .ht-filter--toggle-button {
                    display: inline-block;
                    width: 100%;
                    padding: 6px 0;
                    color: var(--green);
                    font-size: responsive 16px 16px;
                    line-height: 1.6875;
                    text-align: center;
                    text-decoration: none;
                    border: 1px solid var(--green);
                    border-radius: var(--default);
                    transition: all var(--standard);

                    & i {
                        font-size: 19px;
                        line-height: 1;
                        margin-right: 8px;
                        vertical-align: -1px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .ht-vacancies {
        & .ht-wrapper {
            &:after {
                height: calc(calc(100vw - 32px) * 1.15591);
            }

            & .ht-vacancy-search {

                & form {
                    & input {
                        width:70%;
                    }

                    & button {
                        width: 30%;
                    }
                }
            }
        }
    }
}
