.ht-content {
    position: relative;
    width: 100vw;
    margin: 0 auto;

    & .ht-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: 680px;
        margin: 0 auto;
    }

    & .ht-vacancy-entry {
        padding: 32px 0;

        & > p {
            margin: 0;

            & > strong {
                display: inline-block;
                margin: 8px 0 16px;
                color: var(--green);
                font-size: responsive 18px 22px;
                line-height: 1.36363;

                &:first-child {
                    margin: 0;
                    font-size: responsive 23px 31px;
                    line-height: 1.35483;
                }
            }
        }

        & .ht-vacature-tools {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 32px;

            & .button {
                display: inline-block;
            }

            & .ht-share-buttons {
                position: relative;
                display: inline-block;

                & p {
                    margin: 0 0 14px;
                    color: var(--green);
                    font-size: responsive 10px 14px;
                    line-height: 1;
                }

                & a {
                    position: relative;
                    margin: 0 6px 0 2px;
                    color: var(--green);
                    font-size: 21px;
                    line-height: 1;
                    text-decoration: none;
                    transition: all var(--standard);

                    &:last-child {
                        margin: 0;
                    }

                    &:hover {
                        color: var(--yellow);
                    }
                    
                    &.copy-link {
                        position: relative;
                        cursor: pointer;

                        & .tooltip {
                            position: absolute;
                            top: 32px;
                            left: 50%;
                            display: block;
                            width: 170px;
                            height: auto;
                            padding: 8px 16px;
                            color: var(--green);
                            font-size: 16px;
                            background: var(--yellow);
                            border-radius: 5px;
                            box-shadow: var(--box-shadow);
                            pointer-events: none;
                            opacity: 0;
                            text-align: center;
                            transform: translateX(-50%);
                            transition: all var(--delayed);
                        }

                        &.copied .tooltip {
                            opacity: 1;
                            transition: all var(--standard);
                        }
                    }
                }   
            }
        }
    }
}


.ht-global__plaintext {
    padding: 32px 0;
    margin: 0;

    & p {
        font-size: responsive 14px 16px;
        line-height: 1.375;	

        &:first-child {
            font-size: responsive 14px 18px;
            line-height: 1.33333;
            font-weight: var(--light);
            font-style: italic;
            margin-bottom: 32px;
        }
    }

    & img {
        margin: 0 0 40px;
    }

    & ol {
        padding: 0 0 0 26px;
    }

    & ul {
        padding: 0 0 0 26px;
        margin: 0 0 24px;
        text-align: left;
        list-style: none;

        & li {
            position: relative;
            width: 100%;
            margin: 0 0 8px;
            font-size: responsive 14px 16px;
            line-height: 1.375;	

            &:before {
                content: '\f00c';
                position: absolute;
                top: 2px;
                left: -24px;
                color: var(--yellow);
                font-family: 'Font Awesome 5 Pro';
            }

            & a {
                position: relative;
                display: inline-block;
                color: var(--black);
                font-size: responsive 12px 16px;
                line-height: 1.375;	
                text-decoration: none;
                transition: all var(--standard);

                &:after {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 0;
                    height: 1px;
                    bottom: -1px;
                    right: 0;
                    background-color: var(--green);
                    transition: all var(--standard);
                }

                &:hover {
                    color: var(--green);

                    &:after {
                        left: 0;
                        right: auto;
                        width: 100%;
                    }
                }
            }

            & > ul {
                margin-top: 8px;

                & > li {
                    &:before {
                        color: var(--green);
                    }
                }
            }
        }
    }
}

.ht-content.ht-content-2col {
    & .ht-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 994px;
    }

    & .ht-content-column {
        width: 658px;
    }

    & .ht-sidenav-column {
        width: 304px;
        padding: 56px 0 88px;

        & ul {
            margin: 0 0 48px;
            padding: 0;
            list-style: none;

            & > li {
                margin: 0;

                &.current_page_item {
                    & > a {
                        color: var(--green);
                        font-weight: var(--bold);
                    }
                }

                & a {
                    position: relative;
                    display: block;
                    padding: 0 36px 7px 0;
                    margin: 0 0 7px;
                    color: var(--black);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-decoration: none;
                    border-bottom: 1px solid var(--light-grey);
                    transition: all var(--standard);

                    &:hover {
                        color: var(--green);
                    }
                }

                & > ul {
                    margin: 6px 0 7px;

                    & > li {
                        position: relative;

                        &:before {
                            content: '\fe63';
                            position: absolute;
                            top: 0px;
                            left: 0;
                            width: 24px;
                            color: var(--green);
                            font-family: var(--icons);
                            transform: rotateZ(0deg);
                        }

                        & > a {
                            position: relative;
                            display: block;
                            padding: 0 36px 7px 24px;
                            margin: 0 0 7px;
                            color: var(--black);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-decoration: none;

                            &:hover {
                                color: var(--green);
                            }
                        }
                    }
                }
            }
        }
    }
}

.ht-image-inline {
    display: block;
    padding: 32px 0;

    & .ht-image-inline__wide {
        width: 1024px;
        max-width: 100%;
        margin: 0 auto;
        border-radius: var(--default);
    }

    & .ht-image-inline__img {
        max-width: 672px;
        margin: 0 auto;
        border-radius: var(--default);
    }

    & .ht-image-inline__caption {
        display: block;
        width: 100%;
        padding: 24px 0 0;
        color: var(--grey);
        font-family: var(--primary);
        font-size: responsive 12px 16px;
        line-height: responsive 23px 27px;
        text-align: center;
    }
}

.ht-testimonial-content {
    & > h2 {
        margin-top: 32px;
    }

    & .ht-testimonial-intro {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 0 32px 0;
        margin: 0 auto;
        border-bottom: 1px solid var(--light-grey);
        color: var(--black);
        text-decoration: none;

        & + .ht-global__plaintext {
            & p {
                font-size: responsive 14px 16px;
                line-height: 1.375;	
                font-style: normal;

                &:first-child {
                    font-size: responsive 14px 18px;
                    line-height: 1.33333;
                    font-style: italic;
                    font-weight: var(--light);
                }
            }
        }

        & .ht-testimonial--image {
            background-size: 170px;
            background-position: right bottom;
            background-repeat: no-repeat;

            & img {
                width: 200px;
                height: auto;
                max-width: 100%;
                margin: 0 auto;
            }
        }

        & .ht-testimonial---details {
            width: calc(100% - 216px);

            & p {
                &.quote,
                &.quote > span {
                    font-size: responsive 14px 18px;
                    line-height: 1.33333;
                }

                &.name {
                    margin: 0;
                    color: var(--green);
                }
                
                &.job {
                    margin: 0;
                    color: var(--grey);
                    font-size: responsive 10px 14px;
                    line-height: 1.375;	
                }
            }
        }
    }

    & .ht-global__plaintext {
        padding: 32px 0 0;

        & p {
            font-size: responsive 14px 16px;
            line-height: 1.375;	
            font-style: normal;
            font-weight: var(--regular);

            &:last-child {
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .ht-content.ht-content-2col {
        & .ht-wrapper {
            max-width: 876px;
        }

        & .ht-content-column {
            width: 66.19718%;
        }

        & .ht-sidenav-column {
            width: 30.5835%;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-content.ht-content-2col {
        & .ht-wrapper {
            max-width: 812px;
        }
    }
}

@media only screen and (max-width: 876px) {
    .ht-content.ht-content-2col {
        & .ht-wrapper {
            max-width: 768px;
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-content.ht-content-2col {
        & .ht-wrapper {
            max-width: 736px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .ht-content.ht-content-2col {
        & .ht-wrapper {
            max-width: 704px;
        }
    }
}

@media only screen and (max-width: 736px) {
    .ht-content,
    .ht-content.ht-content-2col {
        & .ht-wrapper {
            max-width: calc(100vw - 48px);
        }

        & .ht-content-column {
            width: 100%;
        }

        & .ht-sidenav-column {
            width: 100%;
        }

        & .ht-image-inline {
            & .ht-image-inline__img {
                max-width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .ht-content,
    .ht-content.ht-content-2col {
        & .ht-wrapper {
            max-width: calc(100vw - 32px);
        }

        & .ht-testimonial-content {

            & .ht-testimonial-intro {
                flex-direction: column;

                & .ht-testimonial---details {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }
}
