.ht-vacancy-column {
    width: calc(100% - 400px);
    
    & .ht-overview--toggle {
        display: block;
        width: 232px;
        padding: 4px;
        margin: 0 0 32px auto;
        font-size: 0;
        line-height: 0;
        background-color: var(--near-white);
        border-radius: var(--default);

        & a {
            display: inline-block;
            padding: 6px 24px;
            color: var(--green);
            font-size: responsive 16px 16px;
            line-height: 1.6875;
            text-align: center;
            text-decoration: none;
            border-radius: var(--default);
            transition: all var(--standard);

            & i {
                font-size: 19px;
                line-height: 1;
                margin-right: 8px;
                vertical-align: -1px;
            }

            &:hover {
                background-color: var(--white);
            }

            &.active {
                color: var(--white);
                background-color: var(--green);
            }
        }
    }

    & .ht-vacancy-list {
        display: block;
        opacity: 1;
        transition: opacity var(--standard);

        & .ht-vacancy {
            display: block;
            padding: 32px;
            margin: 0 0 24px;
            background-color: var(--white);
            border-radius: var(--default);
            box-shadow: var(--box-shadow);
            text-decoration: none;
            transition: all var(--standard);

            &:hover {
                box-shadow: var(--box-shadow__single);
            }

            & h2 {
                margin: 0 0 8px;
                hyphens: auto;
            }

            & .ht-vacancy--address {
                position: relative;
                padding-left: 22px;
                margin: 0 0 36px;
                color: var(--dark-grey);

                & i {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    margin: -2px 8px 0 0;
                    color: var(--green);
                    font-size: responsive 19px 19px;
                    line-height: 1;
                    vertical-align: middle;
                }

                &.no-map {
                    padding-left: 0;
                }
            }

            & .ht-vacancy--hours {
                display: inline-block;
                width: 48%;
                color: var(--black);
                font-weight: var(--bold);
            }

            & .ht-vacancy--link {
                display: inline-block;
                width: 50%;
                color: var(--green);
                font-weight: var(--bold);
                text-align: right;

                & i {
                    margin: -2px 0 0 8px;
                    font-size: responsive 19px 19px;
                    line-height: 1;
                    vertical-align: middle;
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .ht-vacancy-column {
        width: 66.19718%;
    }
}

@media only screen and (max-width: 736px) {
    .ht-vacancy-column {
        width: 100%;
    }
}

@media only screen and (max-width: 414px) {
    .ht-vacancy-column {
        & .ht-overview--toggle {
            width: 100%;

            & a {
                display: inline-block;
                width: 50%;
                padding: 6px 0;
            }
        }
    }
}

@media only screen and (max-width: 400px) {
    .ht-vacancy-column {
        & .ht-vacancy-list {
            & .ht-vacancy {
                & .ht-vacancy--link {
                    display: block;
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }
}
