.ht-global__cookies {
    max-width: 1024px;
    margin: 0 auto 80px;
    padding: 56px 88px 51px;
    background: var(--white);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.23),
    0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 8px;
}
.ht-global__cookies .ht-cookie {
    width: 100%;   

    & h2 {
        margin: 0 0 40px;
        text-align: center;
    }
}
.ht-cookie .ht-cookie__link {
    color: var(--light-grey);
    text-decoration: none;
}

.ht-cookie hr.ht-cookie__line {
    border: none;
    border-bottom: 1px solid var(--light-grey);
}

.ht-cookie .ht-cookie__button {
    position: relative;
    display: inline-block;
    padding: 0.7rem 2rem;
    font-family: inherit;
    font-weight: 400;
    text-align: center;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    border-radius: 3px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    will-change: background-color, color, border-color;
}

.ht-cookie .button-group {
    margin-bottom: -36px;
    text-align: center;

    & .ht-cookie__button {

        &:after {
            content: '\f00c';
            display: inline-block;
            width: 0;
            margin-left: 8px;
            font-family: var(--icons);
            font-size: responsive 13px 17px !important;
            line-height: 1.235;
            opacity: 0;
            transition: all var(--standard);
        }

        &.selected {

            &:after {
                width: 17px;
                opacity: 1;
            }
        }

        &:first-child {
            margin-right: 16px;
        }
    }
}

.ht-cookie .button-right {
    margin: 56px 0 -36px;
    text-align: right;
}

.ht-cookie .ht-cookie__button--primary {
    color: var(--white);
    background: var(--green);
}

.ht-cookie .button-gradient {
    min-width: 144px !important;
    padding: 8px;
    color: var(--white);
    background: var(--green);
    margin-left: 8px;
}

.ht-cookie .ht-cookie__button--white {
    color: var(--green);
    background: var(--white);
}

.ht-cookie .ht-cookie__button--primary,
.ht-cookie .ht-cookie__button--white {
    padding: 18px 24px 17px;
    font-size: responsive 15px 19px !important;
    line-height: 1.2105;
    box-shadow: var(--box-shadow);
    transition: all var(--standard);

    &:hover {
        box-shadow: var(--box-shadow__hover);
    }
}

.ht-cookie .ht-cookie__bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    padding: 15px 0;
    margin: 0;
    pointer-events: auto;
    background-color: var(--black);
    opacity: 1;
    transition: all 300ms ease-in-out;
}

.ht-cookie .ht-cookie__wrapper {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
}

.ht-cookie .ht-cookie__bar .ht-cookie__text {
    max-height: 100%;
    padding: 0 15px;
    margin: 0;
    overflow-y: auto;
    font-size: responsive 12px 14px !important;
    line-height: 1.42857;
    color: var(--white);
}

.ht-cookie .ht-cookie__bar .ht-cookie__text .ht-cookie__link {
    display: inline;
}

.ht-cookie .ht-cookie__bar .ht-cookie__agree {
    min-width: 195px;
    margin-right: 15px;
}

.ht-cookie .ht-cookie__bar .ht-cookie__agree:hover {
    cursor: pointer;
}

@media only screen and (min-width: 1025px) {
    .ht-cookie .ht-cookie__wrapper {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__agree {
        min-width: 210px;
    }
}

@media only screen and (max-width: 1024px) {
    .ht-cookie .ht-cookie__wrapper {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 600px) and (orientation: portrait) {
    .ht-global__cookies {
        width: calc(100% - 32px);
        margin: 0 auto 56px;
        padding: 24px 16px 51px;
    }

    .ht-cookie .ht-cookie__wrapper {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__agree {
        margin: 15px 0 0;
    }
}

@media only screen and (max-width: 600px) and (orientation: landscape) {
    .ht-global__cookies {
        width: calc(100% - 32px);
        margin: 0 auto;
        padding: 88px 16px 51px;
    }

    .ht-cookie .ht-cookie__wrapper {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__text {
        overflow: scroll;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__agree {
        margin: 15px 0 0;
    }
}

@media only screen and (max-width: 380px) {
    .ht-cookie .ht-cookie__bar .ht-cookie__text {
        overflow: scroll;
    }

    .ht-cookie .ht-cookie__bar .ht-cookie__agree {
        margin: 15px 0 0;
    }

    .ht-cookie .button-group {

        & .ht-cookie__button {
            display: block;

            &:first-child {
                margin: 0 0 16px;
            }
        }
    }

    .ht-cookie .ht-cookie__button.ht-cookie__save-cookie {
        display: block;
    }
}

.ht-cookie .ht-cookie__settings h4 {
    margin: 60px 0 37px;
    text-align: center;
}

.ht-cookie .ht-cookie__settings .ht-cookie__text .ht-cookie__paragraph,
.ht-cookie .ht-cookie__settings .ht-cookie__option-text .ht-cookie__paragraph {
        margin: 0 0 5px;
}

.ht-cookie .ht-cookie__settings .ht-cookie__text {
    margin: 0 0 30px;
}

.ht-cookie .ht-cookie__settings .ht-cookie__option {
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 54px 40px;
}

.ht-cookie .ht-cookie__settings .ht-cookie__save-cookie {
    margin-top: 15px;
}

.ht-cookie .ht-cookie__toggle-wrapper {
    width: 80px;
    margin: 0 24px 0 0;
    text-align: right;
}

.ht-cookie input.ht-cookie__toggle {
    margin: 0;
    max-width: 0;
    max-height: 0;
    opacity: 0;
    position: absolute;
}

.ht-cookie input.ht-cookie__toggle+span+label.ht-cookie__label {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 14px;
    margin: 0;
    text-indent: -5000px;
    border-radius: 7px;
    background-color: var(--grey);
    cursor: pointer;
}
.ht-cookie input.ht-cookie__toggle+span:before {
    content: 'Uit';
    padding-right: 8px;
    opacity: 0.6;
    font-weight: bold;
    color: var(--black);
    letter-spacing: 0;
    text-align: right;
    line-height: 27px;
}

.ht-cookie input.ht-cookie__toggle:checked + span:before {
    content: 'Aan';
}
.ht-cookie input.ht-cookie__toggle + span + label.ht-cookie__label:before,
.ht-cookie input.ht-cookie__toggle + span + label.ht-cookie__label:after {
    content: '';
    position: absolute;
    top: -3px;
    left: 0;
    display: block;
    opacity: 1;
    width: 20px;
    height: 20px;
    border-radius: 15px;
    background-color: var(--yellow);
    transition: all 250ms ease-in-out;
}

.ht-cookie input.ht-cookie__toggle + span + label.ht-cookie__label:after {
    opacity: 0;
    background-color: var(--green);
    transition: all 250ms ease-in-out;
}
.ht-cookie input.ht-cookie__toggle.disabled + span + label {
    opacity: 0.4;
}
.ht-cookie input.ht-cookie__toggle.disabled + span + label.ht-cookie__label:after {
    background: var(--light-grey);
}
.ht-cookie input.ht-cookie__toggle:checked + span + label.ht-cookie__label:before,
.ht-cookie input.ht-cookie__toggle:checked + span + label.ht-cookie__label:after {
    left: 14px;
    opacity: 1;
    box-shadow: var(--box-shadow);
}
.ht-cookie input.ht-cookie__toggle:checked + span + label.ht-cookie__label:before {
    opacity: 0;
    transition: left 250ms ease-in-out,
    opacity 10ms 240ms ease-in-out;
}

.ht-cookie .ht-cookie__settings .ht-cookie__snackbar {
    padding: 6px 16px;
    margin: 52px 0 0;
    color: var(--white);
    background: var(--green);
    box-shadow: var(--box-shadow);
    border-radius: var(--default);
    opacity: 0;
}

.ht-cookie .ht-cookie__settings .ht-cookie__snackbar--show {
    opacity: 1;
    animation: fade 500ms 2000ms forwards ease-in-out;
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
