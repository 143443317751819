.ht-testimonial {
    padding: 56px 0;
    background-color: var(--green);

    & .ht-wrapper {
        & h2 {
            margin: 0 auto 30px;
            color: var(--yellow);
        }

        & .ht-testimonial--slider {
            & .ht-testimonial--block {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin: 0 27px;
                gap: 40px;

                & .ht-testimonial--photo {
                    position: relative;
                    width: 50%;
                    height: auto;

                    & img {
                        max-width: none;
                        margin: 0 auto;
                        width: 100%;
                        height: 550px;
                        object-fit: cover;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 0;
                        height: 0;
                        border-top: 550px solid var(--green);
                        border-right: 141px solid transparent;
                        transition: none;
                        z-index: 1;
                    }

                    &::after {
                        content: url("../hww/img/play.png");
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 60px;
                        height: 60px;
                        margin: auto;
                        transition: none;
                        z-index: 1;
                    }
                }

                & .ht-testimonial--text {
                    width: calc(50% - 16px);

                    & p.ht-testimonial--quote {
                        margin: 0 0 16px;
                        color: var(--white);
                        font-size: responsive 23px 31px;
                        font-weight: var(--bold);
                        line-height: 1.35483;
                    }

                    & p.ht-testimonial--name {
                        margin: 0;
                        color: var(--yellow);
                    }
                }
            }

            .slick-list {
                margin: 0 -27px;
            }

            & .slick-next,
            & .slick-prev {
                position: absolute;
                z-index: 1;
                width: 30px;
                height: 30px;
                background-color: transparent;
                border: 2px solid var(--yellow);
                border-radius: var(--round);
                outline: none;
                transition: all var(--standard);

                &:hover {
                    background-color: var(--yellow);
                    cursor: pointer;

                    & i {
                        color: var(--green-dark);
                    }
                }

                &:before {
                    content: "";
                }

                & i {
                    color: var(--yellow);
                    font-size: responsive 18px 18px;
                    line-height: 1;
                }
            }

            & .slick-prev {
                top: calc(50% - 72px);
                left: -59px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            & .slick-next {
                bottom: calc(50% + 42px);
                right: -59px;
                padding: 2px 6px 0px 9px;
            }

            & .slick-dots {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: 32px auto 0;

                & li {
                    display: inline-block;
                    margin: 0 4px;

                    & button {
                        width: 10px;
                        height: 10px;
                        font-size: 0;
                        line-height: 0;
                        padding: 0;
                        background-color: var(--white);
                        border: 1px solid var(--green);
                        border-radius: var(--round);
                        appearance: none;
                        cursor: pointer;
                        outline: none;
                    }

                    &.slick-active {
                        & button {
                            background-color: var(--yellow);
                            border: 1px solid var(--yellow);
                        }
                    }
                }
            }
        }
    }
}

.ht-testimonial-list--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 50px 0;
    margin: 0 auto;
    border-bottom: 1px solid var(--light-grey);
    color: var(--black);
    text-decoration: none;

    & .ht-testimonial--image {
        position: relative;
        background-size: 170px;
        background-position: right bottom;
        background-repeat: no-repeat;

        & img {
            width: 200px;
            height: 160px;
            object-fit: cover;
            max-width: 100%;
            margin: 0 auto;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 0;
            border-bottom: 80px solid var(--orange);
            border-left: 44px solid transparent;
            transition: none;
            z-index: 1;
        }
    }

    & .ht-testimonial---details {
        width: calc(100% - 216px);

        & p {
            &.quote,
            &.quote > span {
                font-size: responsive 14px 18px;
                line-height: 1.33333;
            }

            &.name {
                margin: 0;
                color: var(--green);
                font-weight: bold;
            }

            &.job {
                margin: 0;
                color: var(--green);
                font-size: 14px;
                line-height: 1.375;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .ht-testimonial {
        & .ht-wrapper {
            & .ht-testimonial--slider .ht-testimonial--block {
                flex-direction: column;

                & .ht-testimonial--photo {
                    width: 100%;
                    order: 1;
                    margin-bottom: 4px;

                    &::before {
                        border-top: 500px solid var(--green);
                        border-right: 100px solid transparent;
                    }

                    & img {
                        height: 500px;
                        width: 100%;
                        object-position: center;
                    }
                }

                & .ht-testimonial--text {
                    width: 100%;
                    order: 2;
                }
            }
        }
    }
}

@media only screen and (max-width: 960px) {
    .ht-testimonial {
        & .ht-wrapper {
            & .ht-testimonial--slider {
                & .slick-prev {
                    left: -43px;
                }

                & .slick-next {
                    right: -43px;
                }
            }
        }
    }
}

@media only screen and (max-width: 812px) {
    .ht-testimonial {
        & .ht-wrapper {
            & .ht-testimonial--slider {
                & .slick-prev {
                    left: -43px;
                }

                & .slick-next {
                    right: -43px;
                }
            }
        }
    }
}

@media only screen and (max-width: 512px) {
    .ht-testimonial-list--item {
        align-items: center;
        flex-direction: column;

        & .ht-testimonial---details {
            width: 100%;
            text-align: center;
            margin-top: 20px;
        }
    }

    .ht-testimonial .ht-wrapper .ht-testimonial--slider .ht-testimonial--block {
        & .ht-testimonial--photo {
            &::before {
                border-top: 300px solid var(--green);
                border-right: 70px solid transparent;
            }

            & img {
                height: 300px;
                width: 100%;
                object-position: center;
            }
        }
    }
}
