.ht-pagination {
    width: 658px;
    max-width: 100%;
    margin: 24px 0 0 auto;

    & ul {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 0;
        
        & li {
            display: inline-block;
            margin: 0 10px 0 0;

            &:last-child {
                margin: 0;
            }

            & a {
                display: inline-block;
                width: 35px;
                height: 35px;
                padding: 7px 0 8px;
                font-size: responsive 15px 15px;
                line-height: 1.33333;
                border: 1px solid var(--green);
                border-radius: var(--default);
                text-align: center;
                text-decoration: none;
                transition: all var(--standard);

                &:hover {
                    color: var(--white);
                    background-color: var(--green);
                }
            }

            &.previous,
            &.next {
                & a {
                    padding: 8px 0;
                    font-size: responsive 16px 16px;
                    line-height: 1;
                }
            }

            &.active {
                & a {
                    color: var(--white);
                    background-color: var(--green);
                }
            }

            &.inactive {
                & a {
                    opacity: 0.25;

                    &:hover {
                        color: var(--green);
                        background-color: var(--white);
                    }
                }
            }
        }
        
        & li.next span, & li.prev span{
            border: 1px solid #00826d;
            text-align: center;
            width: 35px;
            height: 35px;
            font-size: 16px;
            margin-bottom:0;
            line-height: 1.33333;
            border-radius: 5px;
            display: inline-block;
            padding: 7px 0 8px;
            cursor:pointer;
            color:#00826d;
            opacity: .25;
            &:hover{
                background-color:#00826d;
                color:white;
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .ht-pagination {
        width: 66.19718%;
    }
}

@media only screen and (max-width: 736px) {
    .ht-pagination {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
}

@media only screen and (max-width: 414px) {
    .ht-pagination {
        & ul {
            & li {
                margin: 0 4px 0 0;

                &:last-child {
                    margin: 0;
                }

                & a {
                    width: 32px;
                    height: 32px;
                    padding: 5px 0;
                }

                &.previous,
                &.next {
                    & a {
                        padding: 6px 0 5px;
                    }
                }    
            }
        }
    }
}