.ht-vacancy-nav {
    font-size: 0;
    line-height: 0;
    margin: 0 0 32px;

    & a {
        position: relative;
        display: inline-block;
        width: calc(100% / 3);
        padding: 27px 0 10px;
        font-size: responsive 14px 22px;
        font-weight: bold;
        line-height: 1.36363;
        border-bottom: 5px solid var(--near-white);
        text-align: center;
        text-decoration: none;

        &:after {
            position: absolute;
            display: block;
            content: '';
            width: 0;
            height: 5px;
            bottom: -5px;
            right: 0;
            background-color: var(--green);
            transition: all var(--standard);
        }

        &:hover,
        &.active {
            &:after {
                left: 0;
                right: auto;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 414px) {
    .ht-vacancy-nav {
        & a {
            width: 32%;
            padding: 27px 20px 10px;

            &:last-child {
                width: 36%;
            }
        }
    }
}
@media only screen and (max-width: 338px) {
    .ht-vacancy-nav {
        & a {
            padding: 27px 8px 10px;
        }
    }
}

