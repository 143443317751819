h1, .h1 {
    font-size: responsive 32px 40px;
    line-height: 1.375;
}

h2, .h2 {
    font-size: responsive 23px 31px;
    line-height: 1.35483;
}

h3, .h3 {
    font-size: responsive 18px 22px;
    line-height: 1.36363;
}

h4, .h4 {
    font-size: responsive 14px 18px;
    line-height: 1.33333;
}


p,
input,
textarea,
select,
label,
td,
th,
blockquote,
span,
ul,
ol,
li,
a  {
    font-size: responsive 14px 16px;
    line-height: 1.375;	
}
