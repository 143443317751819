html,
body {
    padding: 0px;
    margin: 0px;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;

    &:not(.locked) {
        overflow-y: auto !important;
    }

    &.locked {
        overflow-y: hidden !important;
    }
}

html {
    height: 100%;
    background-color: var(--white);
}

body {
    width: 100%;
    min-height: 100%;
    color: var(--black);
    font-family: var(--primary);
    font-range: 375px 1366px;
    font-weight: var(--regular);
    background-color: var(--white);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    & section {
        &:not(.ht-header--visual) {
            & .ht-wrapper,
            &.ht-global__cookies {
                transition: transform 500ms ease-out,
                    opacity 300ms 200ms ease-out;
            }

            &.lazyload {
                & .ht-wrapper,
                &.ht-global__cookies {
                    opacity: 0;
                    -ms-transform: translateY(-10vw) !important;
                    transform: translateY(10vw);
                }
            }

            &.lazyloaded {
                & .ht-wrapper,
                &.ht-global__cookies {
                    opacity: 1;
                    -ms-transform: translateY(0) !important;
                    transform: translateY(0);
                }
            }
        }

        &.ht-header--visual {
            transition: opacity 300ms ease-out;

            & .ht-wrapper {
                transition: opacity 500ms 400ms ease-out;
            }

            &.lazyload {
                opacity: 0;

                & .ht-wrapper {
                    opacity: 0;
                }
            }

            &.lazyloaded {
                opacity: 1;

                & .ht-wrapper {
                    opacity: 1;
                }
            }
        }
    }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 h4,
.h4 {
    color: var(--green);
    font-family: var(--primary);
    font-weight: var(--bold);
    margin: 0 0 24px;
}

p,
input,
textarea,
select,
label,
td,
th,
blockquote,
ul,
ol,
span {
    font-size: responsive 16px 16px;
    font-range: 375px 1366px;
    line-height: 1.6875;
    font-weight: var(--regular);
    margin: 0 0 16px;
}

p:last-child {
    margin: 0;
}

a {
    cursor: pointer;
    color: var(--green);
}

::-webkit-input-placeholder {
    color: var(--placeholder);
}
::-moz-placeholder {
    color: var(--placeholder);
}
:-ms-input-placeholder {
    color: var(--placeholder);
}
:-moz-placeholder {
    color: var(--placeholder);
}
